/**
 * Divider
 * ---------
 * props:
 *  - m: [string] (default: 'auto')
 *  - maxWidth: [string] (default: '1200px')
 *  - px: [array] (default: [4, 5, 6])
 */
import React from 'react';

import Block from './Block';

const Divider = React.forwardRef(
  (props, ref) => <Block ref={ref} {...props} />,
);

Divider.defaultProps = {
  my: [4, 5],
};

export default Divider;
