/**
 * Contact
 * -------
 * extends: Block, TextBlock, Button
 * props:
 *  - title: [string] **required**
 *  - label: [string] **required**
 *  - quote: [string] **required**
 *  - dataText: [string] **required**
 *  - to: [string] `[/about]` **required**
 *  - size: [string] `[default]`
 *  - color: [string] `[default]`
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import TextBlock from '../TextBlock';
import Block from '../Block';
import Button from '../Button';
import Sitewdith from '../Sitewidth';

const Wrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const Contact = ({
  title, label, quote, color, size, dataText, to,
}) => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <Wrapper py={[6, null, null, 7]}>
      <Sitewdith
        flexDirection={['column', null, null, 'row']}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <TextBlock
          maxWidth="850px"
          title={title}
          label={label}
          quote={quote}
          color={color}
          size={size}
        />
        <AniLink cover to={to || undefined} bg="hsl(42,100%,55%)">
          <Button data-text={dataText} />
        </AniLink>
      </Sitewdith>
    </Wrapper>
  </ThemeProvider>
);

Contact.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  dataText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  quote: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

Contact.defaultProps = {
  color: 'default',
  quote: '',
  size: '',
  label: '',
};

export default Contact;
