/**
 * Controls
 * --------
 * props:
 *  - isTransitioning: [bool] **required**
 *  - slideToNext: [func] **required**
 *  - slideToPrevious: [func] **required**
 */

import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import arrow from '../../assets/icons/arrow-right.svg';

const Arrow = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ControlPrevious = styled.div`
  transform: rotate(180deg);
    margin-right: 10px;
  @media(min-width: ${themeGet('breakpoints.2')}) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const ControlNext = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  cursor: pointer;

  ${ControlNext},
  ${ControlPrevious} {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: ${themeGet('colors.primary.base')};
  }

  pointer-events: ${props => (props.active ? 'all' : 'none')};

  @media(min-width: ${themeGet('breakpoints.2')}) {
    flex-direction: column;
  }
`;

const Controls = ({
  isTransitioning,
  slideToNext,
  slideToPrevious,
  blockControlsWhileSliding,
}) => (
  <Wrapper
    active={!isTransitioning || !blockControlsWhileSliding}
  >
    <ControlPrevious onClick={slideToPrevious}>
      <Arrow src={arrow} alt="" />
    </ControlPrevious>
    <ControlNext onClick={slideToNext}>
      <Arrow src={arrow} alt="" />
    </ControlNext>
  </Wrapper>
);

Controls.propTypes = {
  blockControlsWhileSliding: PropTypes.bool.isRequired,
  isTransitioning: PropTypes.bool.isRequired,
  slideToNext: PropTypes.func.isRequired,
  slideToPrevious: PropTypes.func.isRequired,
};

export default Controls;
