import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import Block from './Block';
import ImageCard from './Cards/ImageCard';
import AnimationHorizontal from './Animate';
import { Title } from './Text';

const Wrapper = styled(Block)`
  flex-direction: row;
  flex-wrap: wrap;
  /* margin: -10px; */
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;

  &:last-child {
    display: none;
  }

  @media(min-width: ${themeGet('breakpoints.1')}) {
    width: 50%;
    &:last-child {
      display: block;
    }
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    width: 33.33%;

    &:last-child {
      display: none;
    }
  }
`;

const RelatedPortfolioItems = ({ currentTags, title }) => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query AllData {
      allMarkdownRemark
        (filter: { fileAbsolutePath: {regex: "/portfolio/"}})
      {
        edges {
          node {
            frontmatter {
              slug
              title
              general {
                shortTitle
                category
                subcategory
                tags
              }
              header {
                cover {
                  image {
                    ...imageFragment
                  }
                }
              }
              project {
                image {
                  ...imageFragment
                }
              }
            }
          }
        }
      }
    }
  `);

  const urlParts = typeof window !== 'undefined'
    && window.location.pathname.split('/');
  const urlLastPart = urlParts[2];

  const matchedPortfolios = allMarkdownRemark.edges.map((item) => {
    const { frontmatter } = item.node;
    const { general, slug: currentSlug } = frontmatter;

    if (urlLastPart === currentSlug) {
      return null;
    }

    const { tags } = general;
    const matchedTags = tags.filter(tag => currentTags.includes(tag));

    if (matchedTags.length === 0) {
      return null;
    }

    return {
      ...frontmatter,
      matchedTags,
    };
  }).filter(Boolean);

  const randomizeMatchedPortfolios = matchedPortfolios.sort(
    // eslint-disable-next-line no-unused-vars
    (_a, _b) => 0.5 - Math.random()
  ).slice(0, 4);

  return (
    <Wrapper>
      {randomizeMatchedPortfolios.length > 0
        && (
          <AnimationHorizontal direction="btt">
            <Title as="h3" pb={[3, 4, 5]}>{title}</Title>
          </AnimationHorizontal>
        )
      }
      {randomizeMatchedPortfolios
        ? randomizeMatchedPortfolios.map((item, index) => (
          <Content key={item.slug}>
            <AnimationHorizontal direction="btt" delay={index * 100}>
              <ImageCard
                as="h4"
                title={
                  item.general.shortTitle
                    ? item.general.shortTitle
                    : item.title
                }
                  // label={item.general.category}
                subcategory={item.general.subcategory}
                src={item.header.cover.image.childImageSharp.fluid}
                to={`/portfolio/${item.slug}/`}
              />
            </AnimationHorizontal>
          </Content>
        ))
        : null
      }
    </Wrapper>
  );
};

RelatedPortfolioItems.propTypes = {
  currentTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

RelatedPortfolioItems.defaultProps = {
  title: 'Related projects',
};

export default RelatedPortfolioItems;
