import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import GatsbyImage from 'gatsby-image';
import Block from '../../components/Block';
import Layout from '../../components/Layout';
import TextBlock from '../../components/TextBlock';
import Sitewidth from '../../components/Sitewidth';
import AnimationHorizontal from '../../components/Animate';
import RelatedPortfolioItems from '../../components/RelatedPortfolioItems';
import Contact from '../../components/Footer/Contact';
import Swiping from '../../components/Slider/Swiping';
import ScrollProgress from '../../components/ScrollProgress';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled(Block)`
  & > :first-child {
    flex-direction: column;
  }
`;

const ImageWrapper = styled(Block)`
  height: 200px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 700px;
  }
`;

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

const Service = ({ data }) => {
  const { frontmatter: service } = data.markdownRemark;
  return (
    <Layout>
      <Wrapper>
        <Sitewidth py={[4, 5, 6]}>
          <TextBlock
            as="h1"
            title={service.title}
            label=""
            size="default"
            description={service.text}
          />
        </Sitewidth>
        <Content my={[2, 3, 7]} p={[3, 4]}>
          <AnimationHorizontal direction="btt" delay={100}>
            <ImageWrapper>
              <Image
                fluid={service.image.childImageSharp.fluid}
                alt={service.title}
              />
            </ImageWrapper>
          </AnimationHorizontal>
        </Content>
        <ScrollProgress offset={-25} checkpoints={4}>
          <AnimationHorizontal direction="btt">
            <Swiping marginBottom="50px" files={service.slider1} />
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={100}>
            <Swiping marginBottom="50px" files={service.slider2} />
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={200}>
            <Swiping marginBottom="50px" files={service.slider3} />
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={300}>
            <Swiping lastChild marginBottom="50px" files={service.slider4} />
          </AnimationHorizontal>
        </ScrollProgress>
        <Sitewidth py={[4, 5]}>
          <RelatedPortfolioItems currentTags={['Website']} />
        </Sitewidth>
        <Contact
          label="Ready to buzz?"
          title="Get in touch"
          quote="Got big ideas?
        Something that you know could change the game,
        make a difference, or spark a revolution?
        Feel free to reach out to us."
          color="default"
          dataText="Contact us"
          to="/contact"
        />
      </Wrapper>
    </Layout>
  );
};

export default Service;

Service.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const pageQuery = graphql`
  query allServices($slug: String!) {
    markdownRemark(
      frontmatter: {slug: {eq: $slug}}
    ) {
      frontmatter {
        slug
        type
        title
        text
        image {
          ...imageFragment
        }
        slider1 {
          title
          type
          src {
            ...imageFragment
          }
          description
        }
        slider2 {
          title
          type
          src {
            ...imageFragment
          }
          description
        }
        slider3 {
          title
          type
          src {
            ...imageFragment
          }
          description
        }
        slider4 {
          title
          type
          src {
            ...imageFragment
          }
          description
        }
      }
    }
  }
`;
