/**
 * Button
 * ------
 * props:
 *  - data-text: [string] `[Contact us]` **required**
 */

import styled from 'styled-components';
import { themeGet } from 'styled-system';
import themed from 'styled-theming';

import ArrowRight from '../../assets/icons/arrow-right.svg';
import { ease } from '../../utils';

const Button = styled.button`
  position: relative;
  display: inline-block;
  border-radius: 1000px;
  cursor: pointer;
  flex: 0 0 auto;
  white-space: nowrap;

  background: ${themed('mode', {
    dark: themeGet('colors.primary.gradient'),
    light: themeGet('colors.primary.gradient'),
    color: themeGet('colors.black.base'),
  })};
  color: ${themeGet('colors.white.base')};
  font-size: ${themeGet('fontSizes.2')};
  font-weight: ${themeGet('fontWeights.bold')};
  padding: 20px 50px;
  margin-top: 50px;

  transition: opacity 300ms;
  &:hover { opacity: 0.85; }

  &:before {
    content: attr(data-text);
    display: inline-block;
    margin-right: -5px;
    transition: transform 800ms ${ease('out', 'circ')};
  }

  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: -5px;
    background: url(${ArrowRight}) no-repeat center;
    background-size: contain;
    opacity: 0;
    transition: transform 800ms ${ease('out', 'circ')}, opacity 300ms;
  }

  &:hover {
    &:before { transform: translateX(-10px); }
    &:after { transform: translateX(10px); opacity: 1; }
  }
`;

export default Button;
