/**
 * MediaStack
 * ----------
 * extends: Block
 * props:
 *  - type: [string] `[sliding || swiping]` **required**
 *  - files: [arrayOf(object)] **required**
 *  - currentIndex: [number] **required**
 *  - transition: [number] **required**
 *  - isTransitioning: [bool] **required**
 *  - slideToPrevious: [func] **required**
 *  - slideToNext: [func] **required**
 *  - duration: [number] **required**
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';
import { timingFunctions } from 'polished';
import Image from 'gatsby-image';

import Space from '../Space';

// import VideoPlayer from '../VideoPlayer';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 240px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    width: 100%;
    height: 390px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    width: 60%;
  }
`;

const Track = styled.div`
  ${props => props.active && css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${props.slides * 100}%;
    height: 100%;

    transition: transform 1000ms ${timingFunctions('easeOutExpo')};
    transform: 
      translateX(-${props.currentIndex * (100 / props.slides)}%);
  `}
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${props => props.fade && css`
    position: absolute;
    opacity: 0;
    ${(props.delayedIndex === props.currentSlide) && css`
      opacity: 1;
    `}
  `}
`;

const SlideImageGatsby = styled(Image)`
  width: 100%;
  min-height: 100%;
  object-fit: cover;

  ${props => props.lastChild && css`
    & > picture > img {
      top: -130px !important;
    }
  `}
`;

const SlideImageStatic = styled.img`
  width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: ${themeGet('colors.primary.gradient')};

  transition: ${props => (props.animation
    ? `transform ${props.duration}ms ${timingFunctions('easeOutExpo')}`
    : 'none'
  )};
  transform: translateX(${props => props.state * 100}%);
`;

const GradientOnce = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  animation: swipeRight 3500ms ${timingFunctions('easeOutExpo')};

  @keyframes swipeRight {
    0% {
      transform: translateX(-100%);
      background: ${themeGet('colors.primary.gradient')};
    }
    80% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(200%);
      background: ${themeGet('colors.primary.gradient')};
    }
  }
`;

const MediaStack = ({
  type,
  files,
  currentIndex,
  delayedIndex,
  transition,
  isTransitioning,
  duration,
  lastChild,
  enterCount,
}) => (
  <>
    <Wrapper>
      <Track
        slides={files.length}
        currentIndex={currentIndex}
        active={type === 'sliding' ? true : undefined}
      >
        {files && files.map((item, index) => (
          <Slide
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            currentSlide={index}
            currentIndex={currentIndex}
            delayedIndex={delayedIndex}
            fade={type === 'swiping'}
          >
            {item.type === 'image'
              && <SlideImageGatsby fluid={item.src} alt={item.title} />
            }
            {item.type === 'slider'
              && (
              <SlideImageGatsby
                fluid={item.src.childImageSharp.fluid}
                alt={item.title}
                lastChild={lastChild}
              />
              )
            }
            {item.type === 'static'
              && <SlideImageStatic src={item.src} alt={item.title} />
            }
            {item.type === 'video'
              && (
                <video autoPlay playsInline loop muted>
                  <source src={item.src} type="video/mp4" />
                </video>
              )
            }
          </Slide>
        ))}
      </Track>
      {type === 'swiping' && (
        <>
          {files.length > 1
            ? (
              <Gradient
                state={transition}
                animation={isTransitioning}
                duration={duration}
              />
            ) : enterCount > 0 && <GradientOnce />
          }
        </>
      )}
    </Wrapper>
    <Space width="40px" height="40px" />
  </>
);

MediaStack.propTypes = {
  type: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentIndex: PropTypes.number.isRequired,
  delayedIndex: PropTypes.number.isRequired,
  transition: PropTypes.number.isRequired,
  isTransitioning: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
  lastChild: PropTypes.bool,
  enterCount: PropTypes.number,
};

MediaStack.defaultProps = {
  type: 'sliding',
  lastChild: false,
  enterCount: 0,
};

export default MediaStack;
