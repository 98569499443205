/**
 * TextBlock
 * ---------
 * props:
 *  - title: [string] **required**
 *  - label: [string] **required**
 *  - order: [string]
 *  - description: [string]
 *  - quote: [string]
 *  - size: [string] `[default]`
 *  - color: [string] `[default]`
 *  - maxWidth: [string] `[800px]`
 *  - as: [string] `[h1 || h2 || h3]`
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Block from '../Block';
import { Label, Title, Paragraph } from '../Text';
import AnimationHorizontal from '../Animate';
import Divider from '../Divider';

const StyledDivider = styled(Divider)`
  width: 100%;
  height: 2px;

  max-width: ${props => (props.size === 'default'
    ? props.theme.space[6]
    : props.theme.space[7]
  )};

  background: ${props => (props.color === 'default'
    ? props.theme.colors.primary.gradient
    : props.theme.colors.secondary.base
  )};
`;

const TextBlock = ({
  title, label, description, order, size, color, quote, maxWidth, as,
}) => (
  <Block maxWidth={maxWidth}>
    {order === 'title'
      ? (
        <>
          <AnimationHorizontal direction="btt">
            <Title as={as}>{title}</Title>
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={100}>
            <Label>{label}</Label>
          </AnimationHorizontal>
        </>
      )
      : (
        <>
          <AnimationHorizontal direction="btt">
            <Label>{label}</Label>
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={100}>
            <Title as={as}>{title}</Title>
          </AnimationHorizontal>
        </>
      )
    }
    {description
    && (
      <AnimationHorizontal direction="btt" delay={200}>
        <Block flexDirection="column">
          <StyledDivider size={size} color={color} />
          <Paragraph
            as="div"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Block>
      </AnimationHorizontal>
    )}
    {quote
    && (
      <AnimationHorizontal direction="btt" delay={200}>
        <Block>
          <StyledDivider size={size} color={color} />
          <Paragraph
            as="div"
            dangerouslySetInnerHTML={{ __html: quote }}
          />
        </Block>
      </AnimationHorizontal>
    )}
  </Block>
);

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  order: PropTypes.string,
  description: PropTypes.string,
  quote: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  as: PropTypes.string,
};

TextBlock.defaultProps = {
  label: '',
  order: '',
  description: '',
  quote: '',
  size: '',
  color: '',
  maxWidth: '800px',
  as: 'h2',
};

export default TextBlock;
