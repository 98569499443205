import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
// import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import handleViewport from 'react-in-viewport';

import Slider from './Slider';
import TextStack from './TextStack';
import MediaStack from './MediaStack';
import Controls from './Controls';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  ${props => props.marginBottom && css`
    margin-bottom: ${props.marginBottom};
  `}

  @media(max-width: ${themeGet('breakpoints.2')}) {
    flex-direction: column;
  }
`;

const Normalize = styled.div``;

const Swiping = ({
  files,
  label,
  title,
  showControls,
  showButton,
  dataText,
  to,
  color,
  marginBottom,
  maxWidth,
  lastChild,
  translateY,
  innerRef,
  enterCount,
}) => (
  <div ref={innerRef} style={{ width: '100%' }}>
    <Slider count={files.length} blockControlsWhileSliding>
      {props => (
        <Wrapper marginBottom={marginBottom}>
          <MediaStack
            {...props}
            translateY={translateY}
            files={files}
            type="swiping"
            lastChild={lastChild}
            enterCount={enterCount}
          />
          <Normalize>
            <TextStack
              {...props}
              color={color}
              label={label}
              title={title}
              maxWidth={maxWidth}
              files={files}
              showButton={showButton}
              dataText={dataText}
              to={to}
            />
            {showControls && <Controls {...props} />}
          </Normalize>
        </Wrapper>
      )}
    </Slider>
  </div>
);

Swiping.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  showControls: PropTypes.bool,
  showButton: PropTypes.bool,
  dataText: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string,
  marginBottom: PropTypes.string,
  maxWidth: PropTypes.string,
  translateY: PropTypes.string,
  lastChild: PropTypes.bool,
  innerRef: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.func, PropTypes.string]
  ),
  enterCount: PropTypes.number,
};

Swiping.defaultProps = {
  showControls: false,
  showButton: false,
  title: '',
  label: '',
  dataText: '',
  to: '/',
  color: 'yellow',
  marginBottom: '50px',
  maxWidth: '600px',
  translateY: '10px',
  lastChild: false,
  innerRef: '',
  enterCount: 0,
};

const SwipingInView = handleViewport(Swiping);

export default SwipingInView;
