/**
 * TextStack
 * ---------
 * extends: TextBlock
 * props:
 *  - order: [string] `[title]`
 *  - description: [string]
 *  - quote: [string]
 *  - size: [string] `[default]`
 *  - color: [string] `[default]`
 */

import React from 'react';
import { useTransition, animated, config } from 'react-spring';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Block from '../Block';
import Button from '../Button';
import { Label, Title, Paragraph } from '../Text';
import Divider from '../Divider';

const Wrapper = styled(Block)`
  width: 100%;
  @media(min-width: ${themeGet('breakpoints.2')}) {
    max-width: 500px;
    /* min-height: 475px; */
    /* padding-right: 50px; */

    /* ${props => props.maxWidth && css`
      max-width: ${props.maxWidth}px;
      padding-right: 0;
    `} */
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  height: 2px;

  max-width: ${props => (props.size === 'default'
    ? props.theme.space[6]
    : props.theme.space[7]
  )};

  background: ${props => (props.color === 'default'
    ? props.theme.colors.primary.gradient
    : props.theme.colors.secondary.base
  )};
`;

const StyledParagraph = styled(Paragraph)`
  margin-right: 20px;
`;

const TextStack = React.memo(({
  files, currentIndex, label, size, color, showButton, dataText, to, maxWidth,
}) => {
  const transitions = {
    title: useTransition(files[currentIndex].title, item => item, {
      from: { opacity: 0, transform: 'translateY(100%)', position: 'absolute' },
      enter: { opacity: 1, transform: 'translateY(0%)', position: 'static' },
      leave: { opacity: 0, transform: 'translateY(-100%)', position: 'absolute' },
      config: config.gentle,
    }),
    description: useTransition(files[currentIndex].description, item => item, {
      from: { opacity: 0, transform: 'translateY(50%)', position: 'absolute' },
      enter: { opacity: 1, transform: 'translateY(0%)', position: 'static' },
      leave: { opacity: 0, transform: 'translateY(-50%)', position: 'absolute' },
      config: config.gentle,
    }),
    category: useTransition(files[currentIndex].category, item => item, {
      from: { opacity: 0, transform: 'translateY(50%)', position: 'absolute' },
      enter: { opacity: 1, transform: 'translateY(0%)', position: 'static' },
      leave: { opacity: 0, transform: 'translateY(-50%)', position: 'absolute' },
      config: config.gentle,
    }),
  };

  return (
    <Wrapper maxWidth={maxWidth}>
      <Label>{label}</Label>
      {transitions.title.map(({ item, props, key }) => (
        <animated.div key={key} style={{ ...props }}>
          <Title>
            {item}
          </Title>
        </animated.div>
      ))}
      <Block flexDirection="column">
        <StyledDivider size={size} color={color} />
        {transitions.description.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...props }}>
            <StyledParagraph
              as="div"
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </animated.div>
        ))}
        {transitions.category.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...props }}>
            <StyledParagraph
              as="div"
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </animated.div>
        ))}
        {showButton && (
        <AniLink cover to={to} bg="hsl(42,100%,55%)">
          <Button data-text={dataText} />
        </AniLink>
        )}
      </Block>
    </Wrapper>
  );
});

TextStack.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentIndex: PropTypes.number.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  showButton: PropTypes.bool,
  dataText: PropTypes.string.isRequired,
  to: PropTypes.string,
  maxWidth: PropTypes.string,
};

TextStack.defaultProps = {
  label: '',
  size: '',
  color: '',
  to: '',
  maxWidth: '600px',
  showButton: false,
};

export default TextStack;
