/**
 * ImageCard
 * ---------
 * extends: Block
 * props:
 *  - title: [string] **required**
 *  - label: [string] **required**
 *  - src: [string] **required**
 *  - to: [func] **required**
 */

import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
// import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Block from '../../Block';
import { Title, Label } from '../../Text';

const Wrapper = styled(Block)`
  width: 100%;
  /* max-width: 360px; */
  border-radius: 10px;
  box-shadow: 0 0 20px ${themeGet('colors.black.shade.3')};
  overflow: hidden;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: ${themeGet('colors.primary.gradient')};
  
  transform: translateY(100%);
  transition: transform 600ms ${timingFunctions('easeInOutQuint')};
  &:hover {
    transform: translateY(0);
  }
`;
const WrapperImage = styled.div`
  position: relative;
  height: 220px;
  overflow: hidden;
`;

const Text = styled(Block)`
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }    
`;


const Image = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;

  transform: scale(1.1);
  transition: transform 3000ms ${timingFunctions('easeInOut')};
  &:hover {
    transform: scale(1);
  }
`;

const StyledTitle = styled(Title)`
  transition: color 500ms ${timingFunctions('easeInOut')};
`;

const StyledLabel = styled(Label)`
  transition: color 500ms ${timingFunctions('easeInOut')};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  &:hover ${Overlay} {
    transform: translateY(0);
  }

  &:hover ${Image} {
    transform: scale(1);
  }

  &:hover ${StyledTitle},
  &:hover ${StyledLabel} {
    transition: color 500ms ${timingFunctions('easeInOut')};
    color: ${themeGet('colors.white.base')};
  }
`;

const Normalize = styled.div`
  width: 100%;
`;

const ImageCard = ({
  title, label, src, to, subcategory, as,
}) => (
  <Wrapper>
    <Normalize>
      {/* <AniLink cover to={to} bg="hsl(42,100%,55%)"> */}
      <Content as="a" href={to}>
        <WrapperImage>
          <Image fluid={src} />
        </WrapperImage>
        <Text p={[3, 4]}>
          <Overlay />
          <StyledTitle as={as}>{title}</StyledTitle>
          <StyledLabel>{label}</StyledLabel>
          <StyledLabel>{subcategory}</StyledLabel>
        </Text>
      </Content>
      {/* </AniLink> */}
    </Normalize>
  </Wrapper>
);

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  subcategory: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.object]).isRequired,
  as: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ImageCard.defaultProps = {
  label: '',
  as: 'h2',
};

export default ImageCard;
