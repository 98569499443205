/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import { timingFunctions } from 'polished';
import Sitewidth from './Sitewidth';

const ProgressContainer = styled(Sitewidth)`
  position: relative;
`;

const ProgressBar = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 22.5px;
  width: 5px;
  transition: height 500ms;
  height: 100%;
  background: ${themeGet('colors.primary.base')};
  @media(min-width: 1024px) { display: block; }
  @media(min-width: 1295px) { left: 0px; }
`;

const ProgressPointOutter = styled.div`
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 22.5px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  transform: translate(-17.5px, -17.5px);
  @media(min-width: 1024px) { display: flex; }
  @media(min-width: 1295px) { left: 0px; }
`;
/* easing way
  transition: transform 1000ms ${timingFunctions('easeOutCirc')};
  &[data-active="true"] {
    transform: translate(-17.5px, -17.5px) scale(1);
  }
*/

const ProgressPointInner = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 1000ms ${timingFunctions('easeOutCirc')};
  background-color: ${themeGet('colors.primary.base')};
  &[data-checked="true"] {
    transform: scale(1);
  }
`;

export default class ScrollProgress extends Component {
  static defaultProps = {
    children: [],
    offset: 0,
    checkpoints: 1,
  }

  static propTypes = {
    children: PropTypes.node,
    offset: PropTypes.number,
    checkpoints: PropTypes.number,
  }

  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.bar = React.createRef();
    this.points = new Array(this.props.checkpoints).join('.').split('.').map(
      () => ({ outter: React.createRef(), inner: React.createRef() }),
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.recalculate);
    window.addEventListener('resize', this.recalculate);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.recalculate);
    window.removeEventListener('resize', this.recalculate);
  }

  recalculate = () => {
    if (
      window.innerWidth >= 1024
      && this.bar.current
      && this.container.current
    ) {
      const {
        height: progressBarHeight,
      } = this.container.current.getBoundingClientRect();
      const {
        top: progressBarTop,
      } = this.bar.current.getBoundingClientRect();
      const progressBarHeightOffset = (
        progressBarHeight / this.props.checkpoints
      ) * (
        this.props.checkpoints - 1
      );
      const progressBarTopOffset = (window.innerHeight / 2) - progressBarTop;
      const scrolled = Math.max(
        progressBarTopOffset + this.props.offset,
        0,
      ) / progressBarHeightOffset;
      const scrolledCappedCurrent = Math.min(1, scrolled);
      const checkpointsMultiple = 1 / (this.props.checkpoints - 1);
      const scrolledCappedCurrentMultiples = Math.floor(
        scrolledCappedCurrent / checkpointsMultiple
      ) * checkpointsMultiple;
      this.scrolledCappedCached = Math.max(
        scrolledCappedCurrentMultiples,
        this.scrolledCappedCached || 0,
      );
      const scrolledCapped = Math.max(
        this.scrolledCappedCached,
        scrolledCappedCurrent,
      );
      const scrolledWithPointOffset = Math.max(
        progressBarTopOffset + this.props.offset + 20,
        0,
      ) / progressBarHeightOffset;
      // const scrolledWithPointOffset = scrolledCapped + 0.02;
      this.bar.current.style.transformOrigin = '0px 0px';
      this.bar.current.style.height = `${progressBarHeightOffset}px`;
      this.bar.current.style.transform = [
        `translateY(${
          (progressBarHeight / (this.props.checkpoints * 2) + this.props.offset)
        }px)`,
        `scale(1, ${scrolledCapped})`,
      ].join(' ');
      this.points.forEach(({ outter, inner }, i, all) => {
        outter.current.style.top = `${
          ((progressBarHeight / this.props.checkpoints) * i)
          + (progressBarHeight / (this.props.checkpoints * 2))
          + this.props.offset
        }px`;
        const value = Math.min(
          1,
          Math.max(
            0,
            (((1 / (all.length - 1)) * i) - scrolledWithPointOffset) * -1,
          ) * 50,
        );
        if (value === 1 && !inner.current.alreadydone) {
          inner.current.alreadydone = true;
          inner.current.setAttribute('data-checked', true);
        }
      });
    }
  }

  render() {
    return (
      <ProgressContainer ref={this.container}>
        <ProgressBar
          ref={this.bar}
          style={{ transform: 'scale(1, 0)' }}
        />
        {this.points.map(({ outter, inner }, index) => (
          <ProgressPointOutter
            ref={outter}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <ProgressPointInner ref={inner} />
          </ProgressPointOutter>
        ))}
        {this.props.children}
      </ProgressContainer>
    );
  }
}
