/* eslint-disable react/no-unused-state */

/**
 * Slider
 * ------
 * props:
 *  - children: [arrayOf(node)] || [node] **required**
 *  - duration: [number] `[1200]ms`
 *  - delay: [number] `[0]ms`
 *  - timeout: [number] `[3000]ms`
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const sleep = ms => new Promise(res => setTimeout(res, ms));

const ContextSlider = React.createContext('slider');

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      delayedIndex: 0,
      transition: -1,
      isTransitioning: false,
      slideToNext: this.slideToNext,
      slideToPrevious: this.slideToPrevious,
    };

    this.interval = null;
  }

  componentDidMount = () => this.slidePlay();

  handleDelayedTransitioning = async (n) => {
    const { delayedIndex } = this.state;
    const { duration, delay, count } = this.props;

    this.setState({ transition: -n });
    await sleep(100);
    this.setState({
      isTransitioning: true,
      transition: 0,
    });
    await sleep(duration + delay);
    this.setState({
      delayedIndex: (count + delayedIndex + n) % count,
      transition: n,
    });
    await sleep(duration + delay);
    this.setState({ isTransitioning: false });
  }

  slide = (n) => {
    const { currentIndex } = this.state;
    const { count } = this.props;

    this.slidePause();
    this.setState({ currentIndex: (count + currentIndex + n) % count });
    this.slidePlay();

    this.handleDelayedTransitioning(n);
  };

  slideToNext = () => this.slide(1);

  slideToPrevious = () => this.slide(-1);

  slidePlay = () => {
    const { timeout } = this.props;
    this.interval = setInterval(() => {
      this.slideToNext();
    }, timeout);
  }

  slidePause = () => clearInterval(this.interval);

  componentWillUnmount = () => this.slidePause();

  render() {
    const {
      children, duration, delay, timeout, blockControlsWhileSliding,
    } = this.props;
    return (
      <ContextSlider.Provider value={{
        duration, delay, timeout, blockControlsWhileSliding, ...this.state,
      }}
      >
        <ContextSlider.Consumer>
          {children}
        </ContextSlider.Consumer>
      </ContextSlider.Provider>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.any,
  ]).isRequired,
  blockControlsWhileSliding: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
  timeout: PropTypes.number,
  count: PropTypes.number,
};

Slider.defaultProps = {
  duration: 1200,
  delay: 0,
  timeout: 5000,
  blockControlsWhileSliding: false,
  count: 4,
};

export default Slider;
