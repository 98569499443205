import React from 'react';
import styled, { css } from 'styled-components';
import handleViewport from 'react-in-viewport';
import { timingFunctions } from 'polished';
import PropTypes from 'prop-types';

const AnimateH = styled.div`
  display: flex;
  width: 100%;

  opacity: 0;
  transform: ${props => (
    props.direction === 'btt'
      ? 'translateY(30%)'
      : 'translateY(-30%)'
  )};
  transition: opacity 500ms, transform 1000ms ${timingFunctions('easeOutCirc')};
  transition-delay: ${props => props.delay}ms;

  ${props => props.inViewport && css`
    opacity: 1;
    transform: translateY(0);
  `}
`;

const AnimateX = ({
  inViewport, innerRef, children, direction, enterCount, delay,
}) => (
  <AnimateH
    inViewport={inViewport}
    ref={innerRef}
    direction={direction}
    enterCount={enterCount}
    delay={delay}
  >
    {children}
  </AnimateH>
);

AnimateX.propTypes = {
  inViewport: PropTypes.bool.isRequired,
  innerRef: PropTypes.func.isRequired,
  enterCount: PropTypes.number.isRequired,
  delay: PropTypes.number,
  direction: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AnimateX.defaultProps = {
  direction: 'btt',
  delay: 0,
};

const AnimationHorizontal = handleViewport(AnimateX, { rootMargin: '50px' });

export default AnimationHorizontal;
